const fields = {
  name: {
    value: "",
    errorBag: [],
    isDirty: false,
    noValidate: false,
    attrs: {
      id: "name",
      placeholder: "Название ингредиента",
      label: "Назваение ингредиента",
      type: "text",
      always_type: "text",
    },
  },
  image: {
    value: "",
    errorBag: [],
    isDirty: false,
    noValidate: false,
    attrs: {
      id: "image",
      placeholder: "Картинка",
      label: "128x128",
      type: "text",
      always_type: "text",
      hidden: true,
    },
  },
  search: {
    value: "",
    errorBag: [],
    isDirty: false,
    noValidate: false,
    attrs: {
      id: "search",
      placeholder: "Найти картинку по имени",
      label: "Найти картинку по имени",
      type: "text",
      always_type: "text",
    },
  },
};

export { fields };
