<template>
  <section class="ingredients__section">
    <div class="ingredients__controls">
      <button
        class="ingredients__delete ingredients__control"
        @click="ingredientController('deleteIngredient')"
      >
        Удалить
      </button>
      <button
        class="ingredients__append ingredients__control"
        @click="ingredientController('appendIngredient')"
      >
        Добавить
      </button>
      <button
        class="ingredients__append ingredients__control"
        @click="ingredientController('changeIngredient')"
      >
        Редактировать
      </button>
    </div>
    <div class="ingredients__list">
      <v-radio
        class="ingredients__item"
        :value="ingr.attrs.label"
        :attrs="ingr.attrs"
        v-model="active_ingr.value"
        v-for="(ingr, idx) in INGREDIENTS"
        :key="idx"
      >
        <template v-slot:append>
          <img
            :src="ingr.image"
            alt=""
            class="ingredients__item-image"
            :ref="ingr.value"
          />
        </template>
      </v-radio>
    </div>
    <v-dialog
      class="ingredients__dialog"
      :data="ingredients_dialog"
      @adminSave="ingredientController('setIngredient')"
      @adminDelete="ingredientController('deleteIngredient')"
      @closeDialog="closeDialog"
    >
      <form class="ingredients__dialog-form">
        <v-input
          v-for="(field, idx) in ingredients_form.fields"
          :key="idx"
          :attrs="field.attrs"
          :errorBag="field.errorBag"
          v-model="field.value"
          class="ingredients__dialog-input"
        ></v-input>
        <input
          class="ingredients__dialog-image"
          type="image"
          :src="ingredients_form.fields.image.value"
          alt=""
        />
        <div class="ingredients__dialog-images">
          <img
            @click="
              ingredientController(
                'getIngredientLink',
                `${this.$store.state.appURL}/ingredients-icons/${value}`
              )
            "
            v-for="({ value }, idx) in filtredIngredientImages"
            :key="idx"
            :src="`${this.$store.state.appURL}/ingredients-icons/${value}`"
            alt=""
          />
          {{ value }}
        </div>
      </form>
    </v-dialog>
    <v-dialog
      class="ingredients__dialog"
      :data="ingredients_dialog_append"
      @adminSave="ingredientController('appendIngredient')"
      @closeDialog="closeDialog"
    >
      <form class="ingredients__dialog-form">
        <v-input
          v-for="(field, idx) in ingredients_form.fields"
          :key="idx"
          :attrs="field.attrs"
          :errorBag="field.errorBag"
          v-model="field.value"
          class="ingredients__dialog-input"
        ></v-input>
        <input
          class="ingredients__dialog-image"
          type="image"
          :src="ingredients_form.fields.image.value"
          alt=""
        />
        <div class="ingredients__dialog-images">
          <img
            @click="
              ingredientController(
                'getIngredientLink',
                `${this.$store.state.appURL}/ingredients-icons/${value}`
              )
            "
            v-for="({ value }, idx) in filtredIngredientImages"
            :key="idx"
            :src="`${this.$store.state.appURL}/ingredients-icons/${value}`"
            alt=""
          />
          {{ value }}
        </div>
      </form>
    </v-dialog>
  </section>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { fields } from "../../helpers/ingredients_dialog";
export default {
  name: "IngredientsViews",
  data() {
    return {
      ingredients_form: {
        fields,
      },
      clone_ingredients_form: {
        fields: {},
      },
      active_ingr: {
        value: "",
      },
      ingredients_dialog: {
        options: {
          visible: false,
          controll: "",
          require_auth: true,
        },
        title: "Управление ингредиентами",
      },
      ingredients_dialog_append: {
        options: {
          visible: false,
          controll: "",
          require_auth: true,
        },
        title: "Добавление нового ингредиента",
      },
    };
  },
  methods: {
    ...mapActions([
      "GET_INGREDIENTS",
      "GLOBAL_NOTIFICATIONS",
      "CHANGE_INGREDIENT",
      "DELETE_INGREDIENT",
      "APPEND_INGREDIENT",
    ]),
    async closeDialog(data) {
      data.options.visible = false;
    },
    openDialog(dialog) {
      dialog.options.visible = true;
    },
    async ingredientController(action, ...params) {
      let settings = params?.[0];

      if (action == "appendIngredient") {
        try {
          if (this.ingredients_dialog_append.options.visible)
            this.APPEND_INGREDIENT(this.ingredients_form.fields).then((res) => {
              this.GLOBAL_NOTIFICATIONS(res);
              this.ingredients_dialog_append.options.visible = false;
              setTimeout(async () => {
                await this.GET_INGREDIENTS();
                this.active_ingr.value = this.INGREDIENTS?.[0]?.value;
              }, 500);
            });
          else this.openDialog(this.ingredients_dialog_append);
        } catch (error) {
          if (error?.title) {
            this.GLOBAL_NOTIFICATIONS(error);
          }
        }
      }
      if (action == "setIngredient") {
        try {
          let newForm = this.ingredients_form.fields;
          let oldForm = this.clone_ingredients_form.fields;
          await this.CHANGE_INGREDIENT({ newForm, oldForm }).then((res) => {
            this.GLOBAL_NOTIFICATIONS(res);
            this.ingredients_dialog.options.visible = false;
            setTimeout(async () => {
              await this.GET_INGREDIENTS();
              this.active_ingr.value = this.INGREDIENTS?.[0]?.value;
            }, 500);
          });
        } catch (error) {
          if (error?.title) {
            this.GLOBAL_NOTIFICATIONS(error);
          }
        }
      }
      if (action == "deleteIngredient") {
        try {
          await this.DELETE_INGREDIENT({ ingredient: this.activeIngr }).then(
            async (res) => {
              this.GLOBAL_NOTIFICATIONS(res);
              setTimeout(async () => {
                await this.GET_INGREDIENTS();
                this.active_ingr.value = this.INGREDIENTS?.[0]?.value;
              }, 500);
            }
          );
        } catch (error) {
          if (error?.title) {
            this.GLOBAL_NOTIFICATIONS(error);
          }
        }
      }
      if (action == "getIngredientLink") {
        try {
          console.log(settings);
          this.ingredients_form.fields.image.value = settings;
        } catch (error) {
          if (error?.title) {
            this.GLOBAL_NOTIFICATIONS(error);
          }
        }
      }
      if (action == "changeIngredient") {
        try {
          if (this.activeIngr == "") {
            throw {
              title: "Ингредиент не выбран",
              text: "Выберите ингредиент",
              status: "error",
              clean: false,
            };
          }
          this.openDialog(this.ingredients_dialog);
          this.ingredients_form.fields.name.value = this.activeIngr;
          let ingrImage = Array.from(this.INGREDIENTS).filter(
            (item) => item.value == this.activeIngr
          )?.[0];
          this.ingredients_form.fields.image.value = ingrImage?.image;
          this.clone_ingredients_form = JSON.parse(
            JSON.stringify(this.ingredients_form)
          );
        } catch (error) {
          if (error?.title) {
            this.GLOBAL_NOTIFICATIONS(error);
          }
        }
      }
    },
    openImagesStore() {
      console.log(this);
      // dashboard.methods.openImagesStore();
    },
  },
  computed: {
    ...mapGetters(["INGREDIENTS", "FOLDER_IMAGES"]),
    activeIngr() {
      return this.active_ingr.value;
    },
    filtredIngredientImages() {
      let filtredArr = Array.from(this.FOLDER_IMAGES).filter((item) =>
        item.value
          .toLowerCase()
          .includes(this.ingredients_form.fields.search.value.toLowerCase())
      );
      if (filtredArr.length) return filtredArr;
      else return this.FOLDER_IMAGES;
    },
  },
  async mounted() {
    await this.GET_INGREDIENTS();
    this.active_ingr.value = this.INGREDIENTS?.[0].value;
  },
};
</script>
<style lang="scss" scoped>
.ingredients {
  /* .ingredients__section */

  &__section {
  }

  /* .ingredients__controls */

  &__controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  /* .ingredients__delete */

  &__delete {
  }

  /* .ingredients__control */

  &__control {
    max-width: 24%;
    width: 100%;
    padding: 14px;
    border: 1px solid #ff6800;
    background-color: #fff;
    border-radius: 10px;
    cursor: pointer;
    margin-bottom: 12px;
  }

  /* .ingredients__append */

  &__append {
  }

  /* .ingredients__list */

  &__list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  /* .ingredients__item */

  &__item {
  }

  /* .ingredients__item-image */

  &__item-image {
  }
  /* .ingredients__dialog-image */

  &__dialog-image {
    pointer-events: none;
  }
  /* .ingredients__dialog-form */

  &__dialog-form {
  }
  /* .ingredients__dialog-input */

  &__dialog-input {
  }
  /* .ingredients__dialog-images */

  &__dialog-images {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    img {
      max-width: 150px;
      cursor: pointer;
      transition: 0.3s ease;
      border-radius: 10px;
      &:hover {
        background-color: $unactive-accent;
      }
    }
  }
}
</style>

<style lang="scss">
/* .ingredients__item */

.ingredients__item {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  width: 13%;
  height: 168px;
  label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1 1 auto;
    height: inherit;
  }
}
.ingredients__item-image {
  max-width: 100px;
}
</style>
